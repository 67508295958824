import { gql } from '@apollo/client';

export default gql`
  query {
    tags: ListTagsFromLocationProperty {
      id
      name
      locationId
    }
  }
`;
