import { gql } from "@apollo/client";

export default gql`
  query ActivityLog($id: ID!, $locationId: ID!) {
    log(aggregate_id: $id, locationId: $locationId) {
      aggregate_id
      aggregate_type
      aggregate_version
      action
      user {
          id
          name
          last_name
        }
      recorded_at
      metadata {
          changes {
              Changes {
                  key
                  new
                  old
              }
          }
      }
    }
  }
`