import { gql } from "@apollo/client";

export default gql`
  mutation UpdateChecklistTask(
    $id: ID!
    $updateChecklistTaskInput: UpdateChecklistTaskInput!
  ) {
    updateChecklistTask(
      id: $id
      updateChecklistTaskInput: $updateChecklistTaskInput
    ) {
      attachment
      createdAt
      id
      label
      notes
      updatedAt
      taskType
      parent_task_id
      ordering_value
      required
      dataCheckboxChecked
      dataTemperatureValue
      dataTemperatureScale
      dataDropdownValue
      dropdownOptions
      dataNumerical
      dataOnOff
      fulfillmentAttachment
      fulfillmentNote
    }
  }
`;
