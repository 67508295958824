import React from 'react';
import { ButtonStyled } from './styles';

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return <ButtonStyled {...props}>{children}</ButtonStyled>;
};

export default Button;
