import { gql } from '@apollo/client';

export default gql`
  query Project($projectId: ID!) {
    project(id: $projectId) {
      id
      currentCycle {
        id
        endDate
        startDate
      }
      displayName
      description
      serviceCycleId
      status
      projectStartDate
      projectEndDate
      serviceCycle {
        type
        value
        displayName
      }
      currentCycle {
        id
        startDate
        endDate
      }
      nextCycle {
        id
        startDate
        endDate
      }
      projectCycles {
        displayName
        startDate
        endDate
        displayStartDate
        displayEndDate
        percentComplete
        percentTimeComplete
        projectCycleStatus
        isDeleted
        id

        version
      }
      projectJobDetails {
        displayName
        jobAssign {
          autoAssignType
          assignableEmployeeIds
        }
        jobTemplate {
          action
          priority
          notes {
            id
            note
            createdAt
          }
          departments {
            id
            displayName
            departmentCode
          }
          checklistTemplates {
            id
            name
          }
          roles {
            id
          }
          attachments
          jobItem
          dueByTime
          scheduleStartTimeAt
        }
        jobRules {
          context
          property
          operator
          values
        }
      }
      isDeleted
      audit {
        lastModified {
          timestamp

        }
        created {
          timestamp

        }
      }
      version
    }
  }
`;
