import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Auth } from '@optii/shared/components/organisms/Authentication/AuthService';

const instanceId = uuidv4();

// Used to handle logic for logging the user out
export function useSignOut() {
  // const history = useHistory();
  // Here we listen for a successful signout
  // useEffect(_ => {
  //   const observer = Auth.Instance.addActionListener(async action => {
  //     if (action.action === AuthActions.SignOutSuccess) {
  //       // history.replace("login");
  //       // window.location.reload();
  //     }
  //   });
  //   return _ => {
  //     Auth.Instance.removeActionObserver(observer);
  //   };
  // }, []);

  useEffect(() => {
    if (!('serviceWorker' in navigator)) {
      return;
    }
    const handleRemoteSignout = function (event: any) {
      // if this instance started the process, don't log out
      const data = event && event.data && event.data.broadcast;
      if (
        data &&
        data.id === '_optiiSignOut' &&
        data.instanceId !== instanceId
      ) {
        Auth.Instance.signOut();
      }
    };

    navigator.serviceWorker.addEventListener('message', handleRemoteSignout);

    // eslint-disable-next-line consistent-return
    return () =>
      navigator.serviceWorker.removeEventListener(
        'message',
        handleRemoteSignout,
      );
  }, []);
  // Initiate the signout process
  const signOut = () => {
    Auth.Instance.signOut();
    localStorage.setItem('userListScrollY', '0'); // default user list scroll position

    if (!('serviceWorker' in navigator)) {
      return;
    }
    navigator?.serviceWorker?.controller?.postMessage({
      broadcast: { id: '_optiiSignOut', instanceId },
    });
  };

  return { signOut };
}
