/* eslint-disable no-underscore-dangle */
const isLocal = typeof window._env_ === 'undefined';

// check if runtime variables are defined, if not, use process var
const context = isLocal ? process.env : window._env_;

// For now, checking the graphql server to determine env
function getGraphQlEnv() {
  const graphServer = context.REACT_APP_GRAPH_SERVER;
  if (graphServer.match(/\.dev\./)) {
    return 'DEV';
  }
  if (graphServer.match(/\.test\./)) {
    return 'TEST';
  }
  return 'PROD';
}

export function getEnvironment() {
  if (isLocal) return 'LOCAL';

  return getGraphQlEnv();
}

// A great way of debugging environment related configurations
// console.info("Configuration:", {
//   ...context,
//   ENV: getGraphQlEnv()
// });
export default {
  ...context,
  ENV: getGraphQlEnv(),
};
