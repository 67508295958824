import React, { ReactNode, useCallback } from 'react';
import { Close, ConfirmationModal, Footer, Header, Title } from './styles';
import { useTranslation } from 'react-i18next';
import { ButtonQuad } from '@optii/shared/components/atoms/ButtonLink';
import ButtonCaution from '@optii/shared/components/atoms/ButtonCaution';
import { useKeyDown } from '@optii/shared';

interface ConfirmationProps {
  title?: ReactNode;
  children: ReactNode;
  modifiers?: string[];
  buttonModifiers?: string[];
  isCloseVisible?: boolean;
  isFooterHidden?: boolean;
  isCancelButtonHidden?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  loadingMessage?: string;
  isLock?: boolean;
  cancelCmponent?: React.FC<
    React.ButtonHTMLAttributes<HTMLButtonElement> & { modifiers?: string[] }
  >;
  confirmComponent?: React.FC<
    React.ButtonHTMLAttributes<HTMLButtonElement> & { modifiers?: string[] }
  >;
  confirmText: string;
  cancelText?: string;
  onHide: () => void;
  onClose?: () => void;
  onConfirm: () => Promise<void>;
  [key: string]: unknown;
}
const Confirmation: React.FC<ConfirmationProps> = ({
  title,
  children,
  modifiers,
  buttonModifiers,
  isCloseVisible,
  isFooterHidden,
  isCancelButtonHidden,
  isDisabled,
  isLock,
  cancelCmponent,
  confirmComponent,
  confirmText,
  cancelText,
  isLoading,
  loadingMessage,
  onHide,
  onClose,
  onConfirm,
  ...props
}) => {
  const { t } = useTranslation('common');

  const handleConfirm = useCallback(async () => {
    await onConfirm();
    if (!isLock) onHide();
  }, [isLock, onConfirm, onHide]);

  const handleClose = useCallback(() => {
    onHide();
    if (typeof onClose === 'function') onClose();
  }, [onHide, onClose]);

  //Confirm on Enter
  useKeyDown(13, () => {
    if (isDisabled) return;
    handleConfirm();
  });

  //Close on ESC
  useKeyDown(27, () => {
    onHide();
  });

  const ConfirmButton = confirmComponent ?? ButtonCaution;
  const CancelButton = cancelCmponent ?? ButtonQuad;

  return (
    <ConfirmationModal modifiers={modifiers} {...props}>
      <Header data-testid="confirmationModalHeader">
        <Title>{title ?? t('common:Confirmation')}</Title>
        {isCloseVisible && <Close onClick={handleClose} />}
      </Header>
      {children}
      {!isFooterHidden && (
        <Footer>
          {!isCancelButtonHidden && (
            <CancelButton onClick={handleClose} data-testid="cancelBtn">
              {cancelText ?? t('common:Cancel')}
            </CancelButton>
          )}
          <ConfirmButton
            onClick={handleConfirm}
            disabled={isDisabled}
            modifiers={buttonModifiers}
            data-testid="confirmation-btn"
          >
            {isLoading ? loadingMessage ?? t('common:Loading...') : confirmText}
          </ConfirmButton>
        </Footer>
      )}
    </ConfirmationModal>
  );
};

export default Confirmation;
